<template>
  <!-- BEGIN: Content-->
  <div class="app-content content">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top profile__avatar">
            <div class="col-12">
              <h2 class="content-header-title mb-0">
                {{ title }}
              </h2>
            </div>
          </div>
        </div>
        <div
          class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none"
        >
          <div class="mb-1 breadcrumb-right">
            <a
              href="#"
              class="btn btn-icon btn-flat-secondary"
              data-bs-toggle="Search texts"
              data-bs-placement="top"
              title="Search texts"
              @click="$router.push({ name: 'admin.section-labels.search' })"
            ><i data-feather="search" /></a>
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">
            <CompleteTable
              :sorted-field="sortedField"
              :data="data"
              :sort-mapping="sortMapping"
              :title="'result'"
              :total="dataTotal"
              :fetch-path="'sectionLabels/fetch'"
              :filter-path="'sectionLabels/filterIndex'"
            >
              <template v-slot="{field}">
                <td
                  class="text-end"
                >
                  <b-dropdown
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                  >
                    <template #button-content>
                      <i data-feather="more-vertical" />
                    </template>
                    <div
                      class="btn-group dropup dropdown-icon-wrapper d-none d-sm-block"
                    >
                      <b-dropdown-item
                        target="_blank"
                        @click="$router.push({ name: 'admin.section-labels.edit', params: { section: field.section } })"
                      >
                        <i
                          class="me-50"
                          data-feather="edit-3"
                        /> Edit
                      </b-dropdown-item>
                    </div>
                  </b-dropdown>
                  <a
                    href=""
                    class="btn btn-icon btn-light-secondary d-block d-sm-none"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasMenu"
                    aria-controls="offcanvasBottom"
                  ><i
                    data-feather="more-vertical"
                  /></a>
                </td>
              </template>
              <template #customfilters>
                <div class="mb-1">
                  <label
                    for="name"
                    class="form-label"
                  >Name</label>
                  <input
                    type="text"
                    class="form-control"
                    name="name"
                    @change="saveFilter($event.target.value, 'name')"
                  >
                </div>
                <hr>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Menu</label>
                  <v-select
                    label="menu_format"
                    :options="menus"
                    :get-option-key="option => option.menu"
                    @input="saveFilter($event, 'menu')"
                  />
                </div>
                <div class="mb-1">
                  <label
                    for=""
                    class="form-label"
                  >Category</label>
                  <v-select
                    label="section_format"
                    :options="categories"
                    :get-option-key="option => option.section"
                    @input="saveFilter($event, 'category')"
                  />
                </div>
              </template>
            </CompleteTable>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CompleteTable from '../../partials/components/CompleteTable.vue'

export default {
  components: {
    CompleteTable,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      filters: [],
      sortedField: 'Category',
      defaultFields: [
        {
          name: 'Menu',
          checked: true,
          order: 1,
        },
        {
          name: 'Category',
          checked: true,
          order: 2,
        },
        {
          name: 'Number of items',
          checked: true,
          order: 3,
        },
      ],
      sortMapping: {
        Menu: 'menu_format',
        Category: 'section_format',
        'Number of items': 'num_labels_table',
      },
      title: 'Text Managements',
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      data: 'sectionLabels/items',
      dataTotal: 'sectionLabels/itemsTotal',
      categories: 'sectionLabels/itemCategories',
      menus: 'sectionLabels/itemMenus',
      loggedUser: 'auth/admin',
    }),
  },
  async mounted() {
    await this.$store.dispatch('modals/fetchUserFields', 'section_labels')
    await this.$store.dispatch('sectionLabels/fetchCategories')
    await this.$store.dispatch('sectionLabels/fetchMenus')
    await this.$store.dispatch('sectionLabels/filterIndex', this.$store.getters['filters/filters'])
    if (!this.fields || this.fields.length === undefined) { // If user doesnt have default fields, load the true default
      await this.$store.dispatch('modals/fetchFields', {
        fields: this.defaultFields,
        table: 'section_labels',
      })
    }
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async saveFilter(value, field) {
      if (field === 'menu') {
        await this.$store.dispatch('sectionLabels/fetchCategories', value.menu)
      }
      this.$store.dispatch('filters/saveFilters', {
        value,
        field,
      })
    },
    async onSearch(search, store) {
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },

  },
}
</script>
